import { deleteAllCookies, isCookieSet } from "./cookies";

const consentBanner = () => {
    window.addEventListener('load', () => {

        const CONSENT_COOKIE_NAME = 'mtm_cookie_consent';
        var _paq = window._paq = window._paq || []

        /**
         * Require cookie consent before tracking
         */
        _paq.push(['requireCookieConsent']);
        /**
         * Handle given cookie consent
         */
         const handleAccept = () => {
            _paq.push(['rememberCookieConsentGiven']);
            window._mtm.push({ 'event': 'consentGiven' });
            consent.style.visibility = 'hidden';
        }

        /**
         * Handle declined cookie consent
         */
        const handleDecline = () => {
            _paq.push(['forgetCookieConsentGiven'])
            consent.style.visibility = 'hidden';
            window._mtm.push({ 'event': 'consentDenied' });
            deleteAllCookies();
        }

        const consent = document.getElementById('consent');
        const accept = document.getElementById('accept');
        const decline = document.getElementById('decline');
        const settings = document.getElementById('privacySettings');
        const settingsFooter = document.getElementById('privacySettings__footer')

        if (!isCookieSet(CONSENT_COOKIE_NAME)) {
            consent.style.visibility = 'visible';
        } else {
            window._mtm.push({ 'event': 'consentGiven' });
        }

        accept.addEventListener('click', () => {
            handleAccept();
        })

        decline.addEventListener('click', () => {
            handleDecline();
        })

        if(settings) {
          settings.addEventListener('click', () => {
              consent.style.visibility = 'visible';
          })
        }

        settingsFooter.addEventListener('click', () => {
            consent.style.visibility = 'visible';
        })
    })
}

consentBanner();
export default consentBanner;
